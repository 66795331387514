@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+39&family=REM:ital,wght@0,600;1,200&family=Shrikhand&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e9c0c0;
  background: url(https://grainy-gradients.vercel.app/noise.svg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#landingContainer {
  width: 100vw;
  height: 90vh;
  left: 20vh;
  display: flex;
  justify-content: center;
}

#landingTile {
  width: 80vh;
  height: 80vh;
}

#landingImage {
  margin: auto;
  height: inherit;
  width: inherit;
  display: block;
  position: absolute;
  filter: drop-shadow(10px 20px 3px #b6b6b6);
  object-fit: cover;
  user-select: none;
}

#headline {
  font-family: 'Shrikhand';
  letter-spacing: 1.5vh;
  font-stretch: 115%;
  font-weight: 900;
  font-size: 5vh;
  color: white;
  position: absolute;
  padding-top: 2vh;
  padding-left: 38vh;
  z-index: 10;
  text-shadow: 3px 3px #636363, 6px 6px #464646;
  user-select: none;
}

#rainbow {
  position: absolute;
  top: 15vh;
  left: 25vw;
  height: 30vh;
  width: 3vw;
  filter: drop-shadow(7px 7px 3px #b6b6b6);
}

.rainbowElement{
  position: relative;
  width: inherit;
  height: 6vh;
}

#barcode {
  position: absolute;
  left: 72vw;
  top: 65vh;
  width: 3vw;
  height: 20vh;
  font-size: 6vw;
  color: #2b2b2b;
  font-family: 'Libre Barcode 39';
  writing-mode: vertical-rl;
  text-orientation: mixed;
  user-select: none;
}

#tagline{
  position: absolute;
  top: 95vh;
  left: 85vw;
  font-size: 0.9vw;
  color: #2b2b2b;
  font-family: "REM";
}

.productBuy {
  width: 20vw;
  text-align: left;
  background-color: transparent;
  padding-bottom: 3vw;
  filter: drop-shadow(10px 10px 3px #b6b6b6);
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: center;
}

.productTitle {
  font-family: "Shrikhand";
  font-size: 3vh;
  left: 2vw;
  width: 100%;
  z-index: 5;
  top: -20vw;
}

.productDisplay {
  height: 80%;
  max-width: 80%;
  object-fit: contain;
  object-position: left top;
}

#storeContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  width: 80vw;
  margin: auto;
}

.navBar{
  height: 10vh;
  width: 100vw;
  font-family: 'REM';
}

.preNav{
  background-color: #222222;
  color: #dddddd;
  height: 3vh;
  display: flex;
  justify-content: center;
}

.preNavElement{
  margin: auto;
  display: inline-block;
  font-family: 'REM';
  font-weight: 600;
  font-size: 1.8vh;
}

.navElementContainer{
  display: flex;
  justify-content: center;
  margin: auto;
  height: 7vh;
}

.navElement{
  margin: auto;
  margin-left: 20px;
  margin-right: 20px;
  display: inline-block;
  font-family: 'REM';
  font-weight: 600;
  color: #202020;
  font-size: 2.5vh;
  user-select: none;
}

a:link{
  color: #2b2b2b;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: #2b2b2b;
  text-decoration: none;

}

/* mouse over link */
a:hover {
  color: #437fff;
  text-decoration: none;

}

/* selected link */
a:active {
  color: #5699ff;
  text-decoration: none;
}

button {
  background-color: transparent; 
  border: 0.5vh solid #222222;
  color: #222222;
  font-family: 'REM';
  padding: 0.5vh;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.awesomeButton {
  background-color: #C785B1;
  color: #eeeeee;
  border-radius: 2.5vh;
  height: 6vh;
  font-family: 'REM';
  font-size: 1.5vh;
  content: 'ADD TO CART';
  padding: 2vh;
  vertical-align: middle;
  border: none;
  filter: drop-shadow(0px 5px 6px #505050);
}

#proceedButton{
  font-family: 'Shrikhand';
  font-size: 2vh;
}

.variant {
  background-color: #4CAF50; 
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 50px;
}

.productPageTitle{
  color: #222222;
  font-family: 'Shrikhand';
  font-size: 3vw;
  text-shadow: 3px 3px #16828E, 6px 6px #ECB92D;
  filter: drop-shadow(5px 5px 6px #505050);
  z-index: 10;
  padding-bottom: 3vh;
}

.productPageContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
  width: 100vw;
}

.productPageDisplay {
  max-height: 55vh;
  width: 100%;
  object-fit: contain;
  object-position: left center;
}

.productPageContent{
  width: 60vw;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}

.productPageLeftColumn{
  max-width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-right: 2vw;
}

.productPageRightColumn{
  max-width: 40%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.productDescription{
  width: 100%;
  font-family: 'REM';
  font-size: 1.4vh;
  padding-top: 10vh;
}

.productVariants{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 1vw;
}

.variantButton{
  min-width: 4vw;
  font-size: 0.85vw;
}

.productPurchase{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.purchasePrice{
  font-family: 'Shrikhand';
  font-size: 3vh;
  margin: 1vw;
}

#checkoutLinkContainer{
  position: fixed;
  z-index: 11;
  font-family: 'REM';
  display: flex;
  justify-content: center;
  width: 100%;
  top: 87vh;
}

.checkoutItemContainer{
  margin: auto;
  width: 40vw;
  margin-top: 5vh;
}

.checkoutItem{
  margin: auto;
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'REM';
  padding: 2%;
}

.checkoutImageContainer{
  width: 26%;
  padding: 2%;
  height: 100%;
  text-align: right;
}

.checkoutImage{
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 5px 6px #505050);
  object-fit: contain;
}

.checkoutDescription{
  width: 26%;
  padding: 2%;
  text-align: right;
}

.checkoutCount{
  width: 4%;
  padding: 2%;
  text-align: right;
}

.checkoutCost{
  width: 14%;
  padding: 2%;
  text-align: right;
}

.checkoutRemoval{
  width: 5%;
  padding: 2%;
  text-align: right;
  height: 10vh;
  display: flex;
  align-items: center;
}

.removeButton{
  background-color: #DC2929;
  border: none;
  color: #dddddd;
  border-radius: 50%;
  padding: 0px;
  margin: 0px;
  height: 3vh;
  width: 3vh;
}

.checkoutTotalPrice{
  font-family: 'Shrikhand';
  text-align: center;
  font-size: 3vh;
  padding: 2vh;
}

.errorPage{
  text-align: center;
  padding: 10vh;
  font-family: 'REM';
}

.portfolioContainer{
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  margin: auto;
}

.portfolioImage{
  width: 15.5%;
  height: 40vh;
  padding: 0.5%;
  object-fit: cover;
}

.portfolioOverlay{
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 12;
  background-color: #0000006e;
}

.portfolioOverlayImageContainer{
  width: 80%;
  height: 80%;
  margin: auto;
  margin-top: 10vh;
}

.portfolioEnlargedImage{
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.exitOverlayButton{
  height: 3vw;
  width: 3vw;
  border-radius: 1.5vw;
  margin-top: 10vh;
  margin-left: 82vw;
  color: white;
  background-color: #2c2c2c;
  font-size: 1.5vw;
  border: none;
  position: absolute;
}

@media (max-width: 800px)  and (orientation: portrait) {
  #landingTile{
    width: 90vw;
    height: 90vw;
    padding-top: 5vh;
  }

  #headline {
    font-family: 'Shrikhand';
    letter-spacing: 2vw;
    font-size: 5.5vw;
    color: white;
    position: absolute;
    padding-left: 42vw;
  }

  #rainbow {
    position: absolute;
    top: 10vh;
    left: 10vw;
    height: 10vh;
    width: 80vw;
    filter: drop-shadow(7px 7px 3px #b6b6b6);
    display: flex;
  }
  
  .rainbowElement{
    position: relative;
    width: 16vw;
    height: inherit;
  }
  
  #barcode {
    position: absolute;
    top: 80vh;
    left: 0vw;
    width: 100vw;
    text-align: center;
    height: auto;
    font-size: 18vw;
    color: #2b2b2b;
    font-family: 'Libre Barcode 39';
    writing-mode: horizontal-tb;
    text-orientation: mixed;
    user-select: none;
  }
  

  .portfolioImage{
    width: 31%;
    height: 20vh;
    padding: 1%;
    object-fit: cover;
  }

  .portfolioContainer{
    width: 95vw;
  }
  

  .exitOverlayButton{
    height: 5vw;
    width: 5vw;
    border-radius: 2.5vw;
    margin-top: 10vh;
    margin-left: 90vw;
    font-size: 3vw;
  }

  .productBuy {
    width: 44vw;
    height: 44vw;
    background-color: transparent;
    padding: 2vw;
    padding-bottom: 3vw;
    filter: drop-shadow(10px 10px 3px #b6b6b6);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

  }
 
  #storeContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    width: 96vw;
    margin: auto;
  }

  .productTitle {
    font-family: "Shrikhand";
    font-size: 5vw;
    top: 0px;
    width: 100%;
    z-index: 5;
  }

  .navElement{
    margin: auto;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 5vw;
  }

  .productPageTitle{
    color: #222222;
    font-family: 'Shrikhand';
    font-size: 9vw;
    width: 100%;
    text-shadow: 3px 3px #16828E, 6px 6px #ECB92D;
    filter: drop-shadow(5px 5px 6px #505050);
    z-index: 10;
    position: static;
    margin-top: 0vh;
    padding-bottom: 2vh;
  
  }
  
  .productPageContainer{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 0vh;
    width: 100vw;
  }
  
  .productDisplay{
    width: 90%;
    filter: drop-shadow(5px 5px 6px #505050);
    padding-bottom: 0vh;
    object-position: center top;
  }
  
  .productPageContent{
    width: 100vw;
    min-height: 0vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: static;
  }
  
  .productPageLeftColumn{
    max-width: 90vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .productPageRightColumn{
    max-width: 92vw;
    padding: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: clip;
  }
  
  .productDescription{
    padding: 4vw;
    width: 70vw;
  }
  
  .productVariants{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 1vw;
    margin-bottom: 2vw;
  }
  
  .variantButton{
    width: 20vw;
    font-size: 3vw;
  }
  
  .productPurchase{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15vh;
  }
  
  .purchasePrice{
    font-family: 'Shrikhand';
    font-size: 3vh;
    margin: 1vw;
  }

  .checkoutItemContainer{
    margin: auto;
    width: 100vw;
    margin-top: 2vh;
  }
  
  .checkoutItem{
    margin: auto;
    width: 100%;
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'REM';
    padding: 0px;
    padding-bottom: 1vh;
  }

  #checkoutLinkContainer{
    position: fixed;
    z-index: 11;
    font-family: 'REM';
    display: flex;
    justify-content: center;
    width: 100vw;
    top: 87dvh;
  }

  .checkoutImageContainer{
    width: 32%;
    padding: 2%;
    height: 100%;
    text-align: right;
  }

  .checkoutDescription{
    width: 20%;
    padding: 2%;
    text-align: right;
  }

  .removeButton{
    width: 5vw;
    height: 5vw;
  }
    
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}